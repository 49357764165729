<template>
  <el-row justify="center" type="flex">
    <el-col class="clouds__container">
      <el-card class="clouds">
        <h6>{{ $t('clouds') }}</h6>
        <p>{{ $t('cloudsDescription') }}</p>
        <ul class="clouds__list">
          <li
            v-for="cloud in clouds"
            :key="cloud.id"
            class="clouds__item"
            @click="handleSetCloud(cloud.id)"
          >
            <span>{{ cloud.name }}</span>
            <span v-if="cloud.description">{{ cloud.description }}</span>
            <span v-if="cloud.visitedLabel">{{ cloud.visitedLabel }}</span>
            <icon class="clouds__item__arrow" width="16" height="16" name="arrow-right" />
          </li>
        </ul>
        <el-divider></el-divider>
        <el-button class="el-button--stretch" @click="showDialog = true">{{
          $t('addCloud')
        }}</el-button>

        <edit-dialog
          :visible="showDialog"
          :title="$t('actions.submit')"
          :data="[
            {
              name: 'name',
              label: $t('name.label'),
              message: $t('name.required'),
            },
            {
              name: 'description',
              label: $t('description.label'),
              type: 'textarea',
            },
          ]"
          :error="submitError"
          :submit-label="$t('actions.submit')"
          @clearError="submitError = false"
          @close="showDialog = false"
          @submit="handleSubmit"
        />
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import EditDialog from '@/components/EditDialog'

export default {
  name: 'Clouds',

  components: {
    EditDialog,
  },

  metaInfo() {
    return {
      title: this.$i18n.t('clouds'),
    }
  },

  data() {
    return {
      showDialog: false,
      submitError: false,
    }
  },

  computed: {
    ...mapState('data', ['clouds']),
  },

  methods: {
    ...mapMutations('data', ['setActiveCloudId']),
    ...mapActions('data', ['createCloud']),
    async handleSetCloud(cloudId) {
      this.setActiveCloudId(cloudId)
      this.$router.push('/')
    },
    async handleSubmit({ name, description }) {
      const { error } = await this.createCloud({
        name,
        description,
      })

      if (error) {
        this.submitError = true
      } else {
        this.showDialog = false
      }
    },
  },
}
</script>

<i18n>
{
  "en": {
    "clouds": "Clouds",
    "cloudsDescription": "Clouds let you differentiate data between your projects, collaborate with team members and pick use-case data flow configurations.",
    "name": {
      "label": "Name",
      "required": "Please enter cloud name"
    },
    "description": {
      "label": "Description"
    },
    "createCloud": "Create new cloud",
    "addCloud": "Add cloud"
  },
  "ru": {
    "clouds": "Облака",
    "cloudsDescription": "Облака позволяют разграничивать данные между вашими проектами, осуществлять коллаборацию между членами команды, а также использовать шаблоны конфигурации под ваши задачи.",
    "name": {
      "label": "Название",
      "required": "Пожалуйста, введите название облака"
    },
    "description": {
      "label": "Описание"
    },
    "createCloud": "Создать новое облако",
    "addCloud": "Добавить облако"
  }
}
</i18n>

<style lang="scss">
.clouds__container {
  max-width: 540px;
}
.clouds .el-card__body {
  > * {
    margin-bottom: $spacing-s;
  }
}
.clouds__item {
  position: relative;
  padding: $spacing-s;
  list-style: none;
  border: 1px solid $color-layout-light-03;
  border-radius: 4px;
  margin-bottom: $spacing-xs;
  &:hover {
    background-color: $color-layout-light-02;
    cursor: pointer;
    .clouds__item__arrow {
      opacity: 1;
    }
  }
}
.clouds__item__arrow {
  position: absolute;
  top: calc(50% - 16px / 2);
  right: $spacing-xs;
  opacity: 0;
}
</style>
