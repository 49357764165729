var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{attrs:{"justify":"center","type":"flex"}},[_c('el-col',{staticClass:"clouds__container"},[_c('el-card',{staticClass:"clouds"},[_c('h6',[_vm._v(_vm._s(_vm.$t('clouds')))]),_c('p',[_vm._v(_vm._s(_vm.$t('cloudsDescription')))]),_c('ul',{staticClass:"clouds__list"},_vm._l((_vm.clouds),function(cloud){return _c('li',{key:cloud.id,staticClass:"clouds__item",on:{"click":function($event){return _vm.handleSetCloud(cloud.id)}}},[_c('span',[_vm._v(_vm._s(cloud.name))]),(cloud.description)?_c('span',[_vm._v(_vm._s(cloud.description))]):_vm._e(),(cloud.visitedLabel)?_c('span',[_vm._v(_vm._s(cloud.visitedLabel))]):_vm._e(),_c('icon',{staticClass:"clouds__item__arrow",attrs:{"width":"16","height":"16","name":"arrow-right"}})],1)}),0),_c('el-divider'),_c('el-button',{staticClass:"el-button--stretch",on:{"click":function($event){_vm.showDialog = true}}},[_vm._v(_vm._s(_vm.$t('addCloud')))]),_c('edit-dialog',{attrs:{"visible":_vm.showDialog,"title":_vm.$t('actions.submit'),"data":[
          {
            name: 'name',
            label: _vm.$t('name.label'),
            message: _vm.$t('name.required'),
          },
          {
            name: 'description',
            label: _vm.$t('description.label'),
            type: 'textarea',
          },
        ],"error":_vm.submitError,"submit-label":_vm.$t('actions.submit')},on:{"clearError":function($event){_vm.submitError = false},"close":function($event){_vm.showDialog = false},"submit":_vm.handleSubmit}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }